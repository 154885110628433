<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getAutomations"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <button class="btn btn-success btn-sm" @click="showImport">
                    <i class="uil uil-import"></i> Import
                  </button>
                  <router-link
                    :to="{ name: 'business.automations.create' }"
                    class="btn btn-primary btn-sm ml-2"
                  >
                    <i class="uil uil-plus"></i> New Automation
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row mb-md-2">
              <div class="col-md-12">
                <div class="d-flex align-items-center float-right">
                  <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getAutomations"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingAutomations">
              <div class="table-responsive mb-0">
                <b-table
                  :items="automations"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :sort-direction="datatable.queries.sort_direction"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>

                  <template v-slot:cell(active)="data">
                    <b-form-checkbox
                      v-model="data.item.active"
                      switch
                      @change="handleActiveChange(data.item)"
                    >
                    </b-form-checkbox>
                  </template>

                  <template v-slot:cell(name)="data">
                    <div class="d-flex align-items-center">
                      <span class="cursor-pointer pr-2" :class="{ 'text-danger': hasNoKeywords(data.item) }">{{ data.item.name }}</span>
                      <span v-if="hasNoKeywords(data.item)" v-b-tooltip.hover v-b-tooltip.hover.top title="No keywords assigned to this automation!" class="text-danger font-italic cursor-pointer pr-2"><feather type="alert-circle" class="icon-dual-danger" /></span>
                      <span v-if="data.item.share_src_id" v-b-tooltip.hover v-b-tooltip.hover.top title="Shared automation" class="pr-2 cursor-pointer">
                        <feather type="share-2" class="icon-dual-primary" />
                      </span>
                      <span v-if="data.item.keyword_duplicated" v-b-tooltip.hover v-b-tooltip.hover.top title="Keyword is duplicated, It should be unique." class="pr-2 cursor-pointer">
                        <feather type="key" class="icon-dual-danger" />
                      </span>
                    </div>
                  </template>

                  <template v-slot:cell(conversion_rate)="data">
                    <p
                      :class="{
                        'text-danger': data.item.conversion_rate <= 30,
                        'text-primary':
                          data.item.conversion_rate > 30 &&
                          data.item.conversion_rate <= 90,
                        'text-success': data.item.conversion_rate > 90,
                      }"
                    >
                      {{ data.item.conversion_rate }}
                    </p>
                  </template>

                  <template v-slot:cell(opt_ins)="data">
                    <a href="javascript:0;" @click="onOptinLinkClick(data.item)">{{ data.item.opt_ins }}</a>
                  </template>

                  <template v-slot:cell(total_revenue)="data">
                    <p v-if="data.item.total_revenue"> {{ data.item.total_revenue | currency }} </p>
                  </template>

                  <template v-slot:cell(automation_stats)="data">
                    <div style="min-width: 100px;">
                      <button :id="`button-in-automation-${data.item.id}`"  class="btn btn-primary btn-md" >
                        {{ data.item.in_automation }}
                      </button>
                      <b-tooltip :target="`button-in-automation-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.in_automation }}</h2>
                        <span>Contacts Currently In Automation</span>
                      </b-tooltip>
                      <button :id="`button-completed-automation-${data.item.id}`" class="btn btn-success ml-2 btn-md">
                        {{ data.item.completed_automation }}
                      </button>
                      <b-tooltip :target="`button-completed-automation-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.completed_automation }}</h2>
                        <span>Contacts Completed Automation</span>
                      </b-tooltip>
                    </div>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div class="table-actions-group" style="min-width: 130px">
                      <router-link
                        :to="{
                          name: 'business.automations.edit',
                          params: { id: data.item.id },
                        }"
                        class="btn btn-outline-dark"
                      >
                        <b-spinner v-if="processingAutomation === data.item.id" small />
                        <span v-else><i class="uil uil-edit"></i> Edit</span>
                      </router-link>
                      <b-dropdown
                        variant="outline-dark"
                        right
                      >
                      <template v-slot:button-content>
                        <i class="uil uil-angle-down"></i>
                      </template>
                      
                        <b-dropdown-item href="#" @click="share(data.item)">
                          <i class="uil uil-share-alt"></i> Share
                        </b-dropdown-item>
                        <b-dropdown-item href="#" :disabled="loadingClone === data.item.id"
                          @click="clone(data.item)">
                            <i class="uil uil-copy"></i> Clone
                        </b-dropdown-item>
                        <b-dropdown-item href="#"
                          :disabled="processingAutomation === data.item.id"
                          @click="refresh(data.item)">
                          <b-spinner v-if="processingAutomation === data.item.id" small />
                          <span v-else v-b-tooltip.hover title="Refresh automation stats with latest dataset" class="d-block">
                            <i class="uil uil-refresh"></i> Refresh Stats
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item href="#"
                          :disabled="processingAutomation === data.item.id"
                          @click="destroy(data.item)">
                          <b-spinner v-if="processingAutomation === data.item.id" small />
                          <span v-else class="text-danger">
                            <i class="uil uil-trash"></i> Delete
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalAutomations }} campaigns
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalAutomations"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete automation">
      <p v-if="selectedAutomation"
        >Are you sure you want to delete automation "{{ selectedAutomation.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>

    <b-modal v-model="showShareModal" title="Share automation" size="lg">
      <div>
        <textarea
          ref="shareLink"
          v-model="shareLink"
          cols="30"
          rows="6"
          class="form-control"
          readonly
          @click="copyLink"
        ></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyLink">
            Copy the link
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="copyLink"
          ><i class="uil uil-copy"></i> Copy</b-button
        >
        <b-button variant="light" @click="showShareModal = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>

    <b-modal v-model="isShowImport" title="Import automation" size="lg">
      <div>
        <TextInput v-model="importLink" label="Link" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="!importLink" @click="importAutomation">
          <b-spinner v-if="loadingImport" small />
          <span v-else>
            <i class="uil uil-import"></i> Import
          </span>
        </b-button>
        <b-button variant="light" @click="isShowImport = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="isShowCloneModal" title="Clone">
      <p>Are you sure you want to clone this item?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowCloneModal = false"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="handleClone">Clone</b-button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.table-responsive {
  overflow: unset;
}
</style>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { label: 'Start Trigger', key: 'start_trigger_label', sortable: true },
          { label: 'Active', key: 'active', class: 'text-center', sortable: true },
          { key: 'opt_ins', label: 'Opt Ins' },
          { key: 'redemptions', label: 'Redemptions' },
          { key: 'conversion_rate', label: 'Conversion %' },
          { key: 'total_revenue', label: 'Total Revenue' },
          { key: 'automation_stats', label: 'Automation Stats' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'desc',
          keywords: [],
        },
      },
      keywords: [],
      loadingAutomations: false,
      showDeleteModal: false,
      selectedAutomation: null,
      processingAutomation: false,
      loadingClone: null,
      showShareModal: false,
      shareLink: null,
      isShowImport: false,
      importLink: null,
      loadingImport: false,
      isShowCloneModal: false,
    }
  },

  computed: {
    automations() {
      return this.$store.getters['automation/all'] || []
    },

    totalAutomations() {
      return this.$store.getters['automation/total']
    },

    config() {
      return this.$store.getters['app/config']
    },
  },

  mounted() {
    // this.getKeywords()

    this.getAutomations()
  },

  methods: {
    onOptinLinkClick(item) {
      let param = `${item['id']}_${item['name']}`
      this.$router.push({ name: 'business.contacts.index', query: {automation: param} })
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getAutomations()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getAutomations()
    },

    hasNoKeywords(item) {
      return item.keywords.length === 0 && ['keywords', 'redeem-offer'].includes(item.start_trigger)
    },

    getAutomations() {
      this.loadingAutomations = true
      this.$store
        .dispatch('automation/getAll', {
          queries: this.datatable.queries
        })
        .then(() => {
          this.loadingAutomations = false
        })
        .catch(() => {
          this.loadingAutomations = false
        })
    },

    refresh(automation) {
      this.selectedAutomation = automation
      this.processingAutomation = this.selectedAutomation.id

      this.$store
        .dispatch('automation/refresh', this.selectedAutomation.id)
        .then(() => {
          this.processingAutomation = null
        })
        .catch(() => {
          this.processingAutomation = null
        })
    },

    destroy(automation) {
      this.showDeleteModal = true
      this.selectedAutomation = automation
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.processingAutomation = this.selectedAutomation.id

      this.$store
        .dispatch('automation/destroy', this.selectedAutomation.id)
        .then(() => {
          this.processingAutomation = null
          this.getAutomations()
        })
        .catch(() => {
          this.processingAutomation = null
        })
    },

    handleActiveChange(item) {
      this.$store.dispatch('automation/createOrUpdate', { automation: item, reload: false })
        .then(() => {})
        .catch(() => {})
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords.map(c => c.name)
        })
        .catch(() => {
        })
    },

    clone(automation) {
      this.isShowCloneModal = true
      this.selectedAutomation = automation
    },
    
    handleClone() {
      this.loadingClone = true

      this.$store
        .dispatch('automation/clone', this.selectedAutomation.id)
        .then(() => {
          this.loadingClone = false
          this.isShowCloneModal = false
          this.getAutomations()
        })
        .catch(() => {
          this.loadingClone = false
        })
    },

    share(automation) {
      this.shareLink = '/'
      this.showShareModal = true

      const  { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        this.shareLink = `https://${this.config.custom_domain}/business/automations/${automation.unique_id}`;
      } else {
        this.shareLink = `https://${this.config.subdomain}.${this.config.whitelabel.domain}/business/automations/${automation.unique_id}`
      }
    },

    copyLink() {
      this.$refs.shareLink.select()
      this.$refs.shareLink.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    showImport() {
      this.importLink = ''
      this.isShowImport = true
    },

    importAutomation() {
      this.loadingImport = true
      const automationId = this.importLink.replace(/.+\/automations\//, '')
      this.$store
          .dispatch('automation/findByUniqueId', automationId)
          .then((res) => {
              this.loadingImport = false
              this.isShowImport = false
              this.$router.push({ name: 'business.automations.share', params: {id: automationId} })
          })
          .catch(() => {
              this.loadingImport = false
          })
    }
  },
}
</script>
